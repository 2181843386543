import React from 'react';
import {
  Stack,
  Text,
  Button,
  Heading,
  Image,
  Box,
  Grid,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
// import Img from '../../Assets/images/img.png';
import Img11 from "../../Assets/images/Services/01 Network setup and Management/Networl Setup and Managment 1.jpg"
import Img21 from "../../Assets/images/Services/02 Help Desk/1.jpg"
import Img31 from "../../Assets/images/Services/03 Mobile Device and Management/1.jpg"
import Img41 from "../../Assets/images/Services/04 IT infrasturcture and Monitoring/1.jpg"

const ServiceCard = ({ heading, description,image, icon, href }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
      <Link to={'/services/'+heading} onClick={scrollToTop}>
    <Box
      maxW={{ base: 'full', md: '296px' }}
      color={'#fff'}
      boxShadow="lg"
      bg="transparent"
      bgGradient={'linear(to-br, #04254e, rgb(44, 35, 168))'}
      w={'full'}
      borderWidth="1px"
      borderColor={'rgba(255,255,255,10%)'}
      borderRadius={'20px'}
      overflow="hidden"
      p={4} // Increased padding for better spacing
    >
      <Stack align={'start'} justifyContent={'space-between'} height={'100%'}>
        <Flex w={'100%'} alignItems={'center'} justifyContent={'flex-end'}>
          {
            <Image
              src={image}
              alt={heading}
              height={'100px'}
              borderRadius="20px"
            />
          }
        </Flex>

        <Box mt={4} textAlign={'start'} width={'100%'}>
          <Flex
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Heading size="md" display={'flex'} textAlign={'start'}>
              {heading}
            </Heading>
            {icon && <Box>{icon}</Box>} {/* Render icon if provided */}
          </Flex>
          <Text mt={2} p={2} fontSize={'sm'} textAlign={'left'}>
            {description}
            {/* {description.map((item, index) => (
              <React.Fragment key={index}>{item}</React.Fragment>
            ))} */}
          </Text>
        </Box>
      </Stack>
    </Box>
    </Link>
  );
};

const HeroServices = () => {
  const serviceData = [
    {
      id: 1,
      serviceImg1: Img11,
      serviceName: 'Network Setup and Management',
      serviceInfo: 'Installation, configuration, and maintenance of network infrastructure. LAN/WAN setup and network management. Firewall implementation and Security Services. Network monitoring and troubleshooting.',
    },
    {
      id: 2,
      serviceImg1: Img21,
      serviceName: 'Help Desk and Technical Support',
      serviceInfo: 'Our technical support options include remote assistance as well as on-site services. We provide support to resolve both hardware and software problems. The job includes providing user support and training services together with managing and resolving incidents.',

    },
    {
      id: 3,
      serviceImg1: Img31,
      serviceName: 'Mobile Device Management (MDM)',
      serviceInfo: 'Mobile device configuration and provisioning. Application management and deployment. Security and policy enforcement. Remote device tracking and wiping.',
    },
    {
      id: 4,
      serviceImg1: Img41,
      serviceName: 'IT Infrastructure Monitoring',
      serviceInfo: 'Proactive monitoring of servers, networks, and systems. The monitoring process encompasses both performance evaluations and availability assessments. The system features alerting services along with notification capabilities. Capacity planning and optimization.',
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <Box
        p={10}
        // bgGradient="linear(to-r,rgb(6, 7, 43) , #30298E, #30298E, #30298E, rgb(6, 7, 43) )"
        bgGradient="linear(to-b, #01244c, #04254e,rgb(44, 35, 168) )"
        // backgroundColor={'rgb(6, 7, 43)'}
        color={'#fff'}
      >
        <Stack
          spacing={6}
          textAlign={'center'}
          align={'center'}
          maxW="1200px"
          mx="auto"
        >
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            gap="10px"
            align={{ base: 'center', sm: 'flex-start' }}
            justify="center"
          >
            <Box p={2}>
              <Heading
                fontSize={{ base: '3xl', sm: '4xl' }}
                fontWeight={'extrabold'}
              >
                Our services and solutions
              </Heading>
            </Box>

            <Box p={2} textAlign={{ base: 'center', sm: 'left' }}>
              <Text color={'gray.100'} fontSize={{ base: 'sm', sm: 'md' }}>
                Our expert team is dedicated to enhancing your digital presence and ensuring seamless connectivity, empowering you to thrive in a competitive landscape. Join together with us to turn your ideas and concepts into reality and advance your success. Here, we provide creative solutions that are suited to your requirements.
              </Text>
              <Stack
                spacing={{ base: 4, md: 6 }}
                direction={{ base: 'column', md: 'row' }}
                width={'100%'}
                mt={5}
              >
                <Button
                as={Link} onClick={scrollToTop}
  to="/contact"
                  rounded={'full'}
                  px={6}
                  colorScheme={'pink'}
                  bg={'#ED1651'}
                  width={{ base: '100%', md: '25%' }}
                  _hover={{ bg: 'white', color:'#000' }}
                >
                  Contact Us
                </Button>
              </Stack>
            </Box>
          </Flex>
        </Stack>

        {/* <Divider mt={8} color={'rgba(255,255,255,10%)'} /> */}
        <Box mt={12} align={'center'} maxW="1200px" mx="auto">
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            }}
            gap={12}
            justifyItems="start"
          >
            {serviceData.map(serviceItem => (
              <ServiceCard
                key={serviceItem.id}
                heading={serviceItem.serviceName}
                image = {serviceItem.serviceImg1}
                description={serviceItem.serviceInfo}
                href={'#'}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroServices;
