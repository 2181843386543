import React from "react";
import {
  Box,
  Text,
  Flex,
  Heading,
  VStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";

const AboutTop = () => {
  return (
    <Box w={'100%'} align={'center'} maxW="1200px" mx="auto" py={{ base: 12, md: 20 }} px={{ base: 4, md: 6 }}>
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
        gap={8}
        alignItems="start"
      >
        {/* Left Section (Heading) */}
        <GridItem>
          <VStack align={{ base: "center", md: "flex-start" }} spacing={6}>
            <Heading
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
              fontWeight="bold"
              textAlign={{ base: "center", md: "start" }}
            >
              About CODISE-IT: Your Trusted IT Partner
            </Heading>
          </VStack>
        </GridItem>

        {/* Right Section (Paragraph) */}
        <GridItem>
          <Flex px={10}>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              textAlign={{ base: "center", md: "justify" }}
              fontWeight="medium"
            >
              The CODISE-IT team implements innovative IT solutions that transform business operations while delivering operational success and effectiveness. Our goal is to provide organizations with advanced technology and superior service that facilitates their development and achievement in today's rapidly changing digital environment.
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default AboutTop;
