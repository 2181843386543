import React from 'react'
import {
    Box,
    Image,
  } from '@chakra-ui/react';

const AboutImg = ({image}) => {
  return (
    <Box>
      {/* px={{ base: 4, lg: 0 }} align={'center'} maxW="1200px" mx="auto" */}
          <Image
            src={image}
            alt="About Image"
            width="100%"
            height="auto"
            maxH={{ base: '250px', sm: '300px', md: '400px' }}
            objectFit="cover"
            borderRadius="md"
          />
        </Box>
  )
}

export default AboutImg