import {
  Stack,
  Flex,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

const FooterTop = () => {
  return (
    <Flex marginBottom={'0px'}
    paddingBottom={'0px'}
      w={'full'}
      h={'50vh'}
      backgroundImage={
        'url(https://images.unsplash.com/photo-1600267175161-cfaa711b4a81?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
      >
        <Flex alignItems={'space-between'} gap={'300px'}>
          <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
            {/* <Text fontWeight={'bold'} color={'white'}>
              Access to new technologies for ultimate reliability
            </Text> */}
            {/* <Text
              color={'white'}
              fontWeight={700}
              lineHeight={1}
              textAlign={'left'}
              fontSize={useBreakpointValue({ base: '4xl', md: '8xl' })}
            >
              Lets talk about new project
            </Text> */}
            <Stack direction={'row'} >
              {/* <Button
                bg={'pink.400'}
                rounded={'full'}
                color={'white'}
                _hover={{ bg: 'pink.600' }}
              >
                Get a free consultation
              </Button> */}
              {/* <Button
              bg={'whiteAlpha.300'}
              rounded={'full'}
              color={'white'}
              _hover={{ bg: 'whiteAlpha.500' }}>
              Show me more
            </Button> */}
            </Stack>
          </Stack>
          <Stack alignItems={'start'} justifyContent={'center'} maxW={'500px'} textAlign={'start'} gap={'40px'}>
          {/* <Text fontWeight={'bold'} color={'white'}>
              Reach out Now <br/>
              +1 (562) 977-9228
            </Text>
            <Text fontWeight={'bold'} color={'white'}>
            Start the collaboration with us while figuring out the best solution based on your needs.
            </Text> */}
          </Stack>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default FooterTop;
