import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Image,
} from '@chakra-ui/react';

import HeroIG from '../../Assets/images/HeroIG.png';

// components
import HeroCard from '../../Components/HeroCard';
import PartnersSection from '../../Components/Partners';
import HeroServices from '../../Components/HeroServices';
import FooterTop from '../FooterTop';
import { Link } from 'react-router-dom';
// import Award from '../Award';

const HeroSection = () => {
  const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    
  return (
    <Box>
      <Box borderRadius={'0 0 50px 50px'} bgGradient="linear(to-b,#010914, #010914,#010914 ,  #04254e, #01244c )">
        <Box
          bgPosition="center"
          bgSize="cover"
          color={'white'}
          bgRepeat="no-repeat"
          w="full"
          h={{ base: '70vh', md: '80vh' }}
          align={'center'}
          maxW="1200px"
          mx="auto"
        >
          <Flex>
          <Box display="flex" alignItems="center" justifyContent="start">
            <Box p={5}>
              <Stack
                textAlign={'start'}
                align={'start'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}
              >
                <Heading
                  fontWeight={900}
                  fontSize={{ base: '4xl', sm: '4xl', md: '8xl' }}
                  lineHeight={'110%'}
                >
                  <Text as={'span'} color={'#ED1651'}>
                    CODISE-IT,
                  </Text>
                </Heading>
                <Heading
                  fontWeight={900}
                  fontSize={{ base: '3xl', sm: '3xl', md: '6xl' }}
                  lineHeight={'110%'}
                >
                  Your Trusted <br />
                  IT service Provider
                </Heading>
                <Text color={'gray.100'} maxW={'3xl'}>
                We provide experience that we bring to our clients ensures their IT infrastructure maintains reliability, scalability and efficiency while being future-ready whether they function as startups or expanding businesses or operate as established corporations.
                </Text>
                <Stack spacing={6} direction={'row'}>
                  <Button 
                   as={Link}
                    to="/contact"
                    rounded={'full'}
                    onClick={scrollToTop}
                    px={6}
                    colorScheme={'pink'}
                    bg={'#ED1651'}
                    _hover={{ bg: 'white', color:'#000' }}
                  >
                    Get started Now
                  </Button>
                  <Button  as={Link}
                  onClick={scrollToTop}
                    to="/services" rounded={'full'} px={6} colorScheme={'while'}
                    bg={'white'}
                    color={'black'}
                    _hover={{ bg: '#ED1651', color:'white' }}>
                    View more
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box maxW={'510px'}  justifyItems={'center'} alignItems={'center'} display={{ base: 'none', md: 'flex' }} >
            <Image src={HeroIG} alt="Hero Img"/>
          </Box>
          </Flex>
        </Box>
      </Box>

      {/* Others */}
      <HeroCard />
      <PartnersSection />
      <HeroServices />
      {/* <Award /> */}
      <FooterTop />
    </Box>
  );
};

export default HeroSection;
