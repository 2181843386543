import React from 'react';
import {
  Box
} from '@chakra-ui/react';
import SectionTop from '../../Components/SectionTop'
import ContactDets from './ContactDets';
const Contact = () => {
  return (
    <Box>
      <SectionTop title="We are here to help" desc="At CODISE-IT, we value your queries and are ready to assist you with personalized IT solutions. Whether you need expert advice, service details, or support, our team is just a message away."/>
      <ContactDets />
    </Box>
  );
};

export default Contact;
