import {
  Box,
  Container,
  Stack,
  Text,
  VStack,
  HStack,
  Icon,
  Heading,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import SectionTop from "../../../Components/SectionTop"
import { CheckIcon } from '@chakra-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';


import Img12 from "../../../Assets/images/Services/01 Network setup and Management/Network Setup 2.jpg"
import Img22 from "../../../Assets/images/Services/02 Help Desk/2.png"
import Img32 from "../../../Assets/images/Services/03 Mobile Device and Management/2.jpg"
import Img42 from "../../../Assets/images/Services/04 IT infrasturcture and Monitoring/2.jpeg"
import Img52 from "../../../Assets/images/Services/05 Software Development and Integration/2.jpg"
import Img62 from "../../../Assets/images/Services/06 IT Consultation and Project Managment/2.jpg"
import Img72 from "../../../Assets/images/Services/07 Network Security and Cybersecurity/2.jpg"
import Img82 from "../../../Assets/images/Services/08 Server Administration/2.jpg"
import Img92 from "../../../Assets/images/Services/09 Data Backup and Recovery/2.jpg"
import Img102 from "../../../Assets/images/Services/10 Iot/2.jpg"
import AboutImg from '../../About/AboutImg.js';


const Service = () => {
  const params = useParams();

  const serviceData = [
    {
      id: 1,
      serviceName: 'Network Setup and Management',
      serviceImg2: Img12,
      quoteLine: 'Your Path to Seamless Connectivity.',
      mainPara:
        "The backbone of modern organizational operations in today's digital landscape relies on robust network management systems. This pathway allows data transmission to enable communication features while simultaneously enhancing teamwork and operational output. CODISE-IT provides network setup and management services to enhance your business operations regarding efficiency and connectivity security.",
      mainLine: 'Why Choose Us for Network Setup and Management??',
      mainpoints: [
        {
          pointid: 1,
          pointTitle: 'Customized Solutions:',
          pointLine:
            "We create tailored networking solutions for each business that meet unique needs across various organizational sizes.",
        },
        {
          pointid: 2,
          pointTitle: 'End-to-End Services:',
          pointLine:
            'Our network services cover design and implementation while also providing ongoing monitoring and maintenance throughout the network\'s entire lifecycle.',
        },
        {
          pointid: 3,
          pointTitle: 'Proactive Maintenance:',
          pointLine:
            'Prevention is key. The proactive network maintenance we provide identifies and handles potential issues early on to avoid operational disruptions.',
        },
      ],
      serviceInfoMain: 'Our Network Setup and Management Services:',
      serviceInfo: [
        {
          topicid: 1,
          topic: 'Network Design and Architecture:',
          topicPoints: [
            "We perform detailed assessments of your organization's networking needs.",
            "Customized network design and architecture planning.",
            "Scalability planning to accommodate future growth."
          ],
        },
        {
          topicid: 2,
          topic: 'Network Setup and Configuration:',
          topicPoints: [
            'Expert installation and configuration of networking hardware.',
            'Ensuring seamless connectivity and network optimization.',
            'Firewall protection measures while implementing essential network security protocols.',
          ],
        },
        {
          topicid: 3,
          topic: 'Network Monitoring and Maintenance:',
          topicPoints: [
            'Network Monitoring and Maintenance.',
            'Real-time network monitoring for performance and security.',
            'Ongoing maintenance tasks maintain network performance and reliability.',
          ],
        },
        {
          topicid: 4,
          topic: 'Wireless Network Solutions:',
          topicPoints: [
            'Rapid issue identification and resolution.',
            'Setup and optimization of wireless networks.',
            'uninterrupted coverage and performance across all operational domains.',
          ],
        },
        {
          topicid: 5,
          topic: 'Network Security and Compliance:',
          topicPoints: [
            'Secure wireless access points and user authentication.',
            'Robust security measures, including intrusion detection and prevention measures.',
            'Compliance with industry-specific regulations (e.g., HIPAA, GDPR).',
          ],
        },
        {
          topicid: 6,
          topic: 'Disaster Recovery and Redundancy:',
          topicPoints: [
            'Regular security audits and vulnerability assessments.',
            'Developing disaster recovery plans for network outages.',
            'Implementing redundancy and failover processes enables organizations to maintain high availability.',
            'Minimizing operational downtime and data loss in case of network disruptions.',
          ],
        },
      ],
    },
    {
      id: 10,
      serviceImg2: Img102,
      serviceName: 'Internet of Things (IoT) Integration',
      quoteLine: "From Devices to Data, We've Got You Covered.",
      mainPara:
        "Leading the Internet of Things (IoT) revolution and providing innovative IoT solutions that drive companies into the digital age, at CODISE is Our committed team of professionals specializes in designing, deploying, and monitoring IoT solutions that elegantly link sensors, devices, and systems, so translating data into useful insights. Emphasizing creativity and efficiency, we enable our customers to fully use IoT technology to improve operations, maximize resource allocation, and guide smart decisions. Our IoT solutions are customized to fit the particular requirements of every client, thus streamlining production processes, improving supply chain visibility, or enriching customer experiences guarantees that each client stays competitive in the fast changing corporate environment of today. As your IoT partner, trust CODISE to help you to create a smarter and more linked future.",
      mainLine: 'Why Choose Us for IOT related Sercices??',
      mainpoints: [
        {
          pointid: 1,
          pointTitle: 'Comprehensive IoT Solutions:',
          pointLine:
            'Our end-to- end IoT solutions include everything from data analytics and insights to device connectivity. Our all-encompassing strategy guarantees that your IoT ecosystem is strong, scalable, and exactly matched with your corporate goals.',
        },
        {
          pointid: 2,
          pointTitle: 'Industry-Specific Expertise:',
          pointLine:
            "Having years of expertise, we know that every sector has particular needs. Whether your business is manufacturing, healthcare, logistics, or another entirely, we use industry-specific knowledge to customize IoT solutions that fit your particular needs.",
        },
        {
          pointid: 3,
          pointTitle: 'Cutting-Edge Technology:',
          pointLine:
            'Using the newest IoT technology and platforms helps us to keep ahead of the curve. Our knowledgeable staff is always developing their abilities to provide creative ideas using the most modern techniques.',
        },
        {
          pointid: 4,
          pointTitle: 'Data-Driven Insights',
          pointLine:
            'We are aware that IoT is mostly dependent on data. Our solutions not only link devices but also offer practical analysis. Turning data into useful knowledge helps you make wise judgments.',
        },
      ],
      serviceInfoMain: 'Our IOT Related Services:',
      serviceInfo: [
        {
          topicid: 1,
          topic: 'IoT Strategy and Consulting:',
          topicPoints: [
            'Our seasoned IoT advisors define your IoT strategy directly with your company.',
            'We assist in spotting prospects, developing a road plan fit for your company goals, and defining precise targets.',
            'Your IoT journey starts with a clear plan with CODISE.',
          ],
        },
        {
          topicid: 2,
          topic: 'Device Connectivity and Integration:',
          topicPoints: [
            'Your IoT journey starts with a clear plan with CODISE.',
            'Designing secure, reliable, and scalable connectivity solutions that enable real-time data exchange between devices, cloud platforms, and applications.',
          ],
        },
        {
          topicid: 3,
          topic: 'Data Management and Analytics:',
          topicPoints: [
            'Unlock the full potential of your IoT-generated data with our advanced data management and analytics services.',
            'We help you collect, store, process, and analyze vast datasets to derive actionable insights that fuel data-driven decision-making.',
          ],
        },
        {
          topicid: 4,
          topic: 'IoT Application Development:',
          topicPoints: [
            'Leverage our expertise in IoT application development to create customized solutions that enhance your operational efficiency and customer experiences.',
            'From mobile apps to web interfaces, we craft intuitive applications that interact seamlessly with your IoT ecosystem.',
          ],
        },
        {
          topicid: 5,
          topic: 'Security and Compliance:',
          topicPoints: [
            "Whether you're in manufacturing, healthcare, logistics, or any other industry, we provide tailored IoT solutions that address your unique challenges and opportunities.",
            'Our industry expertise allows us to deliver solutions that make a real impact.',
          ],
        },
        {
          topicid: 6,
          topic: 'Industry-Specific Solutions:',
          topicPoints: [
            'Regular security audits and vulnerability assessments.',
            'Developing disaster recovery plans for network outages.',
            'Implementing redundancy and failover solutions for high availability.',
            'Minimizing downtime and data loss in case of network disruptions.',
          ],
        },
      ],
    },
    {
      id: 3,
      serviceImg2: Img92,
      serviceName: 'Data Backup and Recovery',

      quoteLine:
        'Secure Data Backup and Swift Recovery: Your Safety Net in a Digital World',

      mainPara:
        "Enterprise operations are based on data, which also guides the present focus toward digital asset security. Unexpected data loss causes major disturbance of corporate activities. By means of backup and recovery solutions meant to preserve your data and enable quick restoration when needed, CODISE-IT offers strong data protection.",

      mainLine: 'Why Choose Us for Data Backup and Recovery',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our experts in data backup and recovery provide companies of all kinds, including startups and big businesses, efficient protection and restoration options. Our staff keeps informed on new technology developments and industry standards now in use.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            'Solutions for data management have to be tailored to fit the varied requirements of companies. Our data management solutions satisfy requirements of established companies as well as of developing startups. ',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'Our team handles operations and creates whole data backup plans to offer quick recovery solutions preserving data accessibility while lowering service disruptions.',
        },

        {
          pointid: 4,

          pointTitle: 'Rapid Response:',

          pointLine:
            "Every time the system recognizes events of system failure or data loss, it starts its recovery mechanisms. Our response team works 24 hours every day to start immediate recovery procedures.",
        },
      ],
      serviceInfoMain: 'Our Data Backup and Recovery Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Data Backup Strategy:',

          topicPoints: [
            'Assess your data backup needs and business continuity requirements.',

            'Create a tailored data backup and retention strategy.',

            'Determine the most suitable backup methods (local, cloud, hybrid).',
          ],
        },

        {
          topicid: 2,

          topic: 'Data Backup and Replication:',

          topicPoints: [
            'To keep the highest dependability requirements, set up automated data backup systems including integrated redundancy features.',

            'Constant replication for a real-time backup.',

            'Encrypted storage solutions are used by companies to set protection mechanisms shielding their data from illegal access.',
          ],
        },

        {
          topicid: 3,

          topic: 'Disaster Recovery Planning:',

          topicPoints: [
            'Write a thorough disaster recovery plan (DRP).',

            'Test and refine DRP regularly.',

            'Verify a recorded, methodical, exact recovery process.',
          ],
        },

        {
          topicid: 4,

          topic: 'Data Recovery Services:',

          topicPoints: [
            'Quick information retrieval made possible by data recovery technologies follows system failures or incidents causing data loss.',

            'Effective data recovery methods help to minimize data loss when systems fail and downtime during service outages.',

            'On several storage systems and solutions, different data recovery techniques show consistent performance efficacy.',
          ],
        },

        {
          topicid: 5,

          topic: 'Regular Monitoring and Maintenance:',

          topicPoints: [
            'Constant data backup system monitoring.',

            'Standard data backup and recovery tests.',

            'Periodic system updates and upgrades.',
          ],
        },

        {
          topicid: 6,

          topic: 'Security and Compliance:',

          topicPoints: [
            'Put in place robust security protocols to guard your kept records.',

            'Verify adherence to industry-specific data rules.',

            'Use access control policies in concert with encryption techniques to guard private information.',
          ],
        },
      ],
    },
    {
      id: 3,
      serviceImg2: Img22,
      serviceName: 'Help Desk and Technical Support',

      quoteLine:
        'Reliable Help Desk and Technical Support: Your Lifeline to Smooth Operations',

      mainPara:
        "In our technology-reliant economic environment businesses need uninterrupted IT operations to stay competitive. Unexpected technical problems together with IT-related difficulties frequently arise which reduce productivity and create negative feelings among users. The CODISE-IT team utilizes their expert knowledge to deliver first-class Help Desk and Technical Support services which maintain uninterrupted system functionality.",

      mainLine: 'Why Choose Us for Help Desk and Technical Support:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Experience You Can Trust:',

          pointLine:
            'Our support experts along with skilled technicians solve technical issues rapidly thanks to their proven track record. The team sustains technical expertise by obtaining consistent updates that align with the fast pace of technological advancements.',
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions: ',

          pointLine:
            "Organizations need specialized IT support services that match their specific requirements to operate efficiently. Our personalized IT solutions meet your distinct needs regardless of your business size from small-scale operations to large enterprises. ",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Support:',

          pointLine:
            'Our support services deliver complete management across all phases of the IT support lifecycle including issue reporting through problem resolution and continued maintenance tasks. ',
        },

        {
          pointid: 4,

          pointTitle: 'Swift Response:',

          pointLine:
            'Immediate solutions to IT issues become vital when time-sensitive situations call for swift action. Through our rapid response commitment we minimize operational interruptions and ensure businesses maximize productivity while maintaining system availability.',
        },
      ],
      serviceInfoMain: 'Our Help Desk and Technical Support Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: '24/7 Help Desk Support:',

          topicPoints: [
            'Around-the-clock availability to assist with technical issues.',

            'Expert technicians work remotely and provide support at any required time.',

            'Timely response to inquiries, ensuring uninterrupted workflow.',
          ],
        },

        {
          topicid: 2,

          topic: 'Troubleshooting and Issue Resolution:',

          topicPoints: [
            'Swift identification and resolution of IT problems.',

            'In-depth diagnosis to prevent recurring issues.',

            'Proactive measures to address root causes.',
          ],
        },

        {
          topicid: 3,

          topic: 'User Support and Training:',

          topicPoints: [
            'Assistance and proper guidance for end-users.',

            'Workshops anf Training programs to enhance IT literacy among employees.',

            'User-friendly documentation and resources.',
          ],
        },

        {
          topicid: 4,

          topic: 'Incident Management:',

          topicPoints: [
            'Streamlined incident reporting and tracking.',

            'Prioritization of critical issues for rapid resolution.',

            'Thorough documentation of incidents for future reference.',
          ],
        },

        {
          topicid: 5,

          topic: 'Software and Hardware Support:',

          topicPoints: [
            'Experts have essential abilities to fix both hardware malfunctions and software challenges.',

            'Provide assistance with software installation, updates, and patch management.',

            'Recommendations for hardware upgrades and replacements.',
          ],
        },

        {
          topicid: 6,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Regular performance assessments to identify bottlenecks.',

            'Implementing solutions for system and network optimization.',

            'Enhancing overall IT infrastructure performance.',
          ],
        },
      ],
    },
    {
      id: 4,
      serviceImg2: Img32,
      serviceName: 'Mobile Device Management (MDM)',

      quoteLine:
        'Seamless Mobile Device Management (MDM): Your Key to Secure and Efficient Mobility',

      mainPara:
        'In the age of mobility, smartphones, tablets, and other mobile devices have become indispensable tools for businesses. However, managing these devices while ensuring data security and compliance can be challenging. At CODISE-IT, we offer comprehensive Mobile Device Management (MDM) services that empower organizations to embrace mobility without compromising on security.',

      mainLine: 'Why Choose Us for Mobile Device Management (MDM):',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Expertise You Can Count On:',

          pointLine:
            'Our team of MDM specialists possesses extensive experience in managing diverse mobile device environments. We keep abreast of the latest mobile technologies and security protocols.',
        },

        {
          pointid: 2,

          pointTitle: 'End-to-End MDM Services: ',

          pointLine:
            "Recognizing that every organization has unique mobile device management needs, our solutions are tailored to meet your specific requirements, whether you're a small business or a large enterprise.",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Support:',

          pointLine:
            'From initial setup and configuration to ongoing monitoring and support, we offer comprehensive MDM services that cover the entire lifecycle of mobile devices in your organization.',
        },

        {
          pointid: 4,

          pointTitle: 'Data Security:',

          pointLine:
            'We prioritize data security and implement robust measures to safeguard your sensitive information on mobile devices, ensuring compliance with industry regulations.',
        },
      ],
      serviceInfoMain: 'Our Mobile Device Management (MDM) Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Device Enrollment and Configuration:',

          topicPoints: [
            'Streamlined device enrollment for easy onboarding.',

            "Customized device configurations based on your organization's policies.",

            'Installation and updates of business apps and profiles.',
          ],
        },

        {
          topicid: 2,

          topic: 'Security and Compliance:',

          topicPoints: [
            'Implementation of strong security measures, including encryption and remote data wipe capabilities.',

            'Regular compliance assessments to ensure adherence to industry-specific regulations.',

            'Monitoring for device compliance and security policy enforcement.',
          ],
        },

        {
          topicid: 3,

          topic: 'App Management:',

          topicPoints: [
            'Centralized app deployment and management.',

            'Control over app distribution, updates, and removal.',

            'App whitelisting and blacklisting to maintain security and productivity.',
          ],
        },

        {
          topicid: 4,

          topic: 'Remote Support and Troubleshooting:',

          topicPoints: [
            'Real-time remote support to resolve device issues.',

            'Troubleshooting and problem resolution for mobile devices.',

            'Efficient issue tracking and resolution.',
          ],
        },

        {
          topicid: 5,

          topic: 'User Training and Education:',

          topicPoints: [
            'Training programs to educate users on MDM best practices.',

            'User-friendly resources and documentation for self-help.',

            'Promoting mobile security awareness among employees.',
          ],
        },

        {
          topicid: 6,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Continuous monitoring for device performance and battery life.',

            'Implementing optimizations to enhance device speed and responsiveness.',

            'Regular assessments to ensure mobile device performance aligns with business needs.',
          ],
        },
      ],
    },

    {
      id: 5,
      serviceImg2: Img42,
      serviceName: 'IT Infrastructure Monitoring',

      quoteLine:
        'Proactive IT Infrastructure Monitoring: Keeping Your Business Ahead of the Curve',

      mainPara:
        "Organizations must have dependable IT infrastructure to reach success in today's digital environment. Effective management of multiple serious challenges is necessary to ensure both operational efficiency and system security in IT environments. CODISE-IT delivers IT infrastructure monitoring solutions which create real-time insights to detect potential problems before they happen while sustaining maximum operation performance.",

      mainLine: 'Why Choose Us for IT Infrastructure Monitoring:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Experience and Expertise:',

          pointLine:
            'Our company employs IT monitoring experts who bring extensive knowledge of various IT environments. We keep our team knowledgeable about the latest technology innovations and industry best practices.',
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions:',

          pointLine:
            "Our organization recognizes that each organization requires unique IT monitoring solutions. Our IT monitoring solutions adapt for organizations of every size to meet their unique needs.",
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'Our IT infrastructure monitoring services deliver full coverage of your IT landscape from the initial setup through continuous monitoring and reporting.',
        },

        {
          pointid: 4,

          pointTitle: 'Proactive Approach:',

          pointLine:
            'Proactive monitoring techniques enable us to detect problems at early stages to protect your business operations from disruptions. Our dedicated team works round-the-clock to maintain seamless system operations and minimize system downtime.',
        },
      ],
      serviceInfoMain: 'Our IT Infrastructure Monitoring Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Network Monitoring:',

          topicPoints: [
            'Uninterrupted network device surveillance while measuring bandwidth usage together with system performance metrics.',

            'Proactive identification of network bottlenecks and issues.',

            'Through historical data analysis we detect system trends and identify optimization areas',
          ],
        },

        {
          topicid: 2,

          topic: 'Server Monitoring:',

          topicPoints: [
            'Constant monitoring of server health, resource utilization, and performance.',

            'Alerts to predict server outages and identify performance declines.',

            'Optimization recommendations for server infrastructure.',
          ],
        },

        {
          topicid: 3,

          topic: 'Application Monitoring:',

          topicPoints: [
            'Comprehensive monitoring of critical applications and services.',

            'Performance tracking and alerting for application issues.',

            'Application availability and response time monitoring.',
          ],
        },

        {
          topicid: 4,

          topic: 'Security Monitoring:',

          topicPoints: [
            'Detection of security threats and suspicious activities in real-time.',

            'Through dedicated compliance-oriented monitoring services CODISE-IT ensures adherence to security standards.',

            'Full Log analysis alongside incident response for security breaches.',
          ],
        },

        {
          topicid: 5,

          topic: 'Cloud Monitoring:',

          topicPoints: [
            'Monitoring of cloud resources and services in hybrid and multi-cloud environment.',

            'Resource utilization tracking and cost optimization.',

            'Automated scaling based on real-time data.',
          ],
        },

        {
          topicid: 6,

          topic: 'Reporting and Analytics:',

          topicPoints: [
            'Tailored reports and dashboards to track key performance metrics.',

            'Data analysis for insights and identification of developmental patterns which drive infrastructure improvements',

            'Regular reporting to stakeholders for transparency and better decision making.',
          ],
        },
      ],
    },

    {
      id: 5,
      serviceImg2: Img52,
      serviceName: 'Software Development and Integration',

      quoteLine:
        'Organizations gain operational efficiency through integrated software development solutions.',

      mainPara:
        'Software systems operate as fundamental elements within contemporary business operations that provide crucial functions while maintaining operational efficiency. Organizations can both optimize operations and encourage innovation through custom software solutions that fulfill their business requirements. Through its software development and integration services CODISE-IT helps organizations achieve their full technological potential.',

      mainLine: 'Why Choose Us for Software Development and Integration:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our team of software experts and integration specialists consistently delivered exceptional solutions through their performance. The team maintains deep knowledge of modern programming languages while understanding contemporary framework systems and integration methods.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            'We understand the unique software requirements of each business we serve. We develop customized solutions to fit your business goals while offering assistance to address your company\'s challenges.',
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Services:',

          pointLine:
            'Our software development services cover everything from initial concept creation through to complete integration with your existing systems. ',
        },

        {
          pointid: 4,

          pointTitle: 'Commitment to Excellence:',

          pointLine:
            'We exceed standard software development practices which makes our products have standout quality. Quality performance together with user experience forms the primary focus of our software development practices.',
        },
      ],
      serviceInfoMain: 'Our IT Infrastructure Monitoring Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Custom Software Development:',

          topicPoints: [
            'Collaborative software design and architecture planning.',

            'Creates custom software applications tailored to your business operations.',

            'Designs that are user-centric for intuitive and efficient UX at maximum speed.',
          ],
        },

        {
          topicid: 2,

          topic: 'Legacy System Integration:',

          topicPoints: [
            'Continuous integration of new software solutions with your existing systems.',

            'Data management operations synchronize and transition data while maintaining data integrity during uninterrupted system operations.',

            'Strategic enhancements provide legacy systems with performance upgrades and functional improvements.',
          ],
        },

        {
          topicid: 3,

          topic: 'API Development and Integration:',

          topicPoints: [
            'Through our development processes we create APIs that enable robust integration capabilities between various software components.',

            'Through connecting your system with external Application Programming Interfaces (APIs) you can improve its capabilities while reaching new data sources.',

            'Secure and scalable API architecture design.',
          ],
        },

        {
          topicid: 4,

          topic: 'Cloud Application Development:',

          topicPoints: [
            'Innovative development methods enable the creation of scalable and flexible cloud-native applications.',

            'By moving active applications to cloud infrastructure you can reduce your system\'s operational costs and improve access to applications.',

            'Implement microservices architecture for agility and resilience.',
          ],
        },

        {
          topicid: 5,

          topic: 'Mobile App Development:',

          topicPoints: [
            'Develop native mobile applications that work seamlessly across both Apple iOS and Google Android platforms through cross-platform support.',

            'Mobile app design, development, testing, and deployment.',

            'Connecting to backend systems and data sources remains a fundamental requirement.',
          ],
        },

        {
          topicid: 6,

          topic: 'Quality Assurance and Testing:',

          topicPoints: [
            'Comprehensive testing and control measures to identify and solve defects.',

            'Performance testing, security testing, and user acceptance testing.',

            'Continuous testing throughout the development lifecycle.',
          ],
        },
      ],
    },

    {
      id: 6,
      serviceImg2: Img62,
      serviceName: 'IT Consulting and Project Management',

      quoteLine: 'Your Trusted IT Consulting and Project Management Partner',

      mainPara:
        'Technical enterprises require expert advisors who can navigate their IT systems and projects through complicated technological landscapes. CODISE-IT offers dependable IT consulting and project management solutions as your trusted partner. The combination of our industry expertise and proven methods ensures your IT projects reach higher efficiency and innovative development.',

      mainLine: 'Explore the benefits we offer in IT consulting and project management services.',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            "Our team combines deep industry expertise with practical experience from our experienced IT consultants and project managers. Through our project delivery track record we have accomplished successful implementations of IT solutions for both startups and enterprise corporations throughout various industry sectors.",
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            "Our company understands that no two businesses require the same approach. We develop customized IT solutions and project management strategies that align with your distinct objectives and obstacles.",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Services:',

          pointLine:
            "Our full project lifecycle services encompass initial consultation through project execution and extend to ongoing support. You receive complete support throughout all stages of your project.",
        },

        {
          pointid: 4,

          pointTitle: 'Proven Methodologies:',

          pointLine:
            'The success of our project management depends on well-established industry practices such as Agile, Waterfall and Six Sigma methodologies. Our process adapts to meet the unique needs of your project.',
        },
      ],
      serviceInfoMain: 'Our IT Consulting Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'IT Strategy and Planning:',

          topicPoints: [
            'Collaboratively define your IT vision and objectives.',

            'Create an IT strategy that fully supports your business goals.',

            'Identify technological solutions which support innovation and improve operational work processes.',
          ],
        },

        {
          topicid: 2,

          topic: 'Technology Assessment and Recommendations:',

          topicPoints: [
            'Carry out an extensive review of organization\'s current IT systems.',

            'Provide recommendations for hardware and software upgrades.',

            'Ensure IT spending yields maximum benefits by striking the right balance between minimizing costs and delivering superior performance.',
          ],
        },

        {
          topicid: 3,

          topic: 'IT Project Management:',

          topicPoints: [
            'Manage IT projects from initiation through to final delivery so they succeed in every phase.',

            'Define project scope, objectives, and deliverables.',

            'Ensure each project finishes within its planned time frame and budget limits.',
          ],
        },

        {
          topicid: 4,

          topic: 'Change Management and Training:',

          topicPoints: [
            'Guide your team through technology transitions.',

            'Develop change management initiatives to support smooth technology adoption.',

            'Organizations need to deliver training programs and support services to maximize the potential of their technology resources.',
          ],
        },

        {
          topicid: 5,

          topic: 'IT Security and Compliance:',

          topicPoints: [
            'Assess and enhance your IT security measures.',

            'Ensure compliance with industry-specific regulations.',

            'Ensure your data is protected by implementing robust cybersecurity measures.',
          ],
        },
      ],
    },

    {
      id: 7,
      serviceImg2: Img72,
      serviceName: 'Network Security and Cybersecurity',

      quoteLine:
        'Protecting Your Digital Fortress: Expert Network Security and Cybersecurity Services',

      mainPara:
        'In the digital age robust Network security and cybersecurity measures become essential on an unprecedented level. CODISE-IT functions as your dedicated partner to guard digital assets against persistent and future cyber threats. Our expert knowledge combined with sophisticated solutions keeps your business safe and resilient from cyber threats.',

      mainLine: 'Why Choose Us for Network Security and Cybersecurity:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our cybersecurity professionals demonstrate deep expertise through extensive experience and up-to-date knowledge of current cyber threats and trends. Our proactive approach delivers strong protection for your digital systems.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            'We recognize that each business is unique. Our cybersecurity solutions protect your business by targeting its unique vulnerabilities while ensuring compliance requirements are met and your risk tolerance levels are respected.',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'We deliver end-to-end cybersecurity services which span from risk assessments and security strategy development to threat detection and incident response to meet all your security needs.',
        },

        {
          pointid: 4,

          pointTitle: 'Continuous Monitoring and Adaptation:',

          pointLine:
            'Cyber threats are dynamic. We maintain continuous surveillance to take preventive measures in response to new threats and weaknesses in systems.',
        },
      ],
      serviceInfoMain: 'Our Network Security and Cybersecurity Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Cybersecurity Assessment and Strategy:',

          topicPoints: [
            'Evaluate your existing security posture.',

            'Develop a comprehensive cybersecurity strategy.',

            'Align security with your business objectives.',
          ],
        },

        {
          topicid: 2,

          topic: 'Managed Security Services:',

          topicPoints: [
            '24/7 monitoring of your network and systems.',

            'Rapid threat detection and response.',

            'Ongoing security patch management.',
          ],
        },

        {
          topicid: 3,

          topic: 'Vulnerability Assessment and Penetration Testing:',

          topicPoints: [
            'Identify weaknesses and vulnerabilities.',

            'Conduct penetration testing to assess security measures.',

            'Implement remediation strategies to fortify defenses.',
          ],
        },

        {
          topicid: 4,

          topic: 'Security Awareness Training:',

          topicPoints: [
            'Educate your employees about cybersecurity best practices.',

            'Conduct simulated phishing exercises to test awareness.',

            'Promote a security-focused environment throughout your organization.',
          ],
        },

        {
          topicid: 5,

          topic: 'Incident Response and Recovery:',

          topicPoints: [
            'Develop incident response plans and procedures.',

            'Work to minimize damage by responding swiftly to any security breach incidents.',

            'Minimize damage and downtime during incidents.',
          ],
        },

        {
          topicid: 6,

          topic: 'Regulatory Compliance:',

          topicPoints: [
            'Ensure compliance with industry-specific regulations (e.g., GDPR, HIPAA).',

            'Conduct compliance assessments and audits.',

            'Implement necessary controls to meet compliance requirements.',
          ],
        },
      ],
    },

    {
      id: 8,
      serviceImg2: Img82,
      serviceName: 'Server Administration',

      quoteLine:
        'Seamless Server Administration: Your Foundation for Uninterrupted Business',

      mainPara:
        'Your servers form the essential structure of your business operations throughout the digital era. Maintaining smooth operation while ensuring security and scalability remains the top priority. CODISE-IT stands as your dependable server administration ally who provides custom solutions to maintain server optimization and ensure smooth business operations.',

      mainLine: 'Why Choose Us for Server Administration:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            "Our certified server administration team has broad expertise in running multiple server environments. We maintain our commitment to keeping abreast of the newest technologies and industry best practices.",
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions:',

          pointLine:
            'We are aware that different businesses require unique server solutions. Our personalized approach meets your specific needs regardless of whether you operate with a single server or a complex multi-server setup.',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'We provide complete server administration services that include server setup and configuration along with continuous monitoring, security management, and performance optimization to fulfill your requirements.',
        },

        {
          pointid: 4,

          pointTitle: '24/7 Support:',

          pointLine:
            "Servers operate beyond regular business hours and our support matches that schedule. We provide constant support to monitor your servers and quickly address any issues that arise throughout the entire day and night cycle.",
        },
      ],
      serviceInfoMain: 'Our Server Administration Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Server Setup and Configuration:',

          topicPoints: [
            'Server installation and configuration based on your specific requirements.',

            'OS installation, patch management, and software setup.',

            'Networks and firewalls to optimize both security measures and system performance.',
          ],
        },

        {
          topicid: 2,

          topic: 'Security and Data Protection:',

          topicPoints: [
            'Server security protection is reinforced through firewalls and intrusion detection systems.',

            'Regular security audits and vulnerability assessments.',

            'Data backup and disaster recovery planning.',
          ],
        },

        {
          topicid: 3,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Analyzing server performance to tune systems for maximum speed and efficiency.',

            'Resource allocation combined with load balancing strategies ensures high availability through optimal distribution.',

            'Scalability planning to accommodate business growth.',
          ],
        },

        {
          topicid: 4,

          topic: 'Server Monitoring and Maintenance:',

          topicPoints: [
            'We maintain servers with continuous monitoring that allows us to detect and resolve problems as they happen.',

            'Scheduled maintenance includes updates alongside patch installation and regular system inspections.',

            'Proactive problem resolution to prevent downtime.',
          ],
        },

        {
          topicid: 5,

          topic: 'User and Access Management:',

          topicPoints: [
            'User account management, access control, and permissions.',

            'Establish role-based access control (RBAC) to improve system security.',

            'Ensure compliance with data protection regulations.',
          ],
        },

        {
          topicid: 6,

          topic: 'Server Virtualization:',

          topicPoints: [
            'Virtualization solutions to optimize server resources.',

            'Establish virtual machines (VMs) and oversee their operation to maximize cost savings.',

            'Server consolidation to reduce hardware footprint.',
          ],
        },
      ],
    },
  ];
  function findServiceByName(serviceName) {
    for (let i = 0; i < serviceData.length; i++) {
      if (serviceData[i].serviceName === serviceName) {
        return serviceData[i];
      }
    }
    return null;
  }
  const serviceObj = findServiceByName(params.id);
  return (
    <Box>
      <SectionTop title="Why Codise IT??" desc="At CODISE-IT, we combine technical expertise with a customer-focused approach to provide bespoke IT solutions designed to meet your unique needs. Our services are crafted to help you achieve operational excellence, drive business growth, and maintain robust security. Partner with us to transform your IT landscape into a strategic asset."/>
      <Box>
      {serviceObj ? (
        <Box>
          <Box>
      <Box align={'center'} maxW="1200px" mx="auto">
        <Stack direction={{ base: 'column', lg: 'row' }}>
          {/* Content Section */}
          <Stack
            flex={2}
            color={'gray.900'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 14, xl: 14 }}
            px={{ base: 5 }}
          >
            <Stack alignItems={'start'} mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={'heading'}
                fontWeight={700}
                textTransform={'uppercase'}
                textAlign={'left'}
                fontSize={{ base: 'lg', md: 'xl' }} // Responsive font size
                color={'gray.900'}
              >
                {serviceObj.quoteLine}
              </Text>
              <Heading
                textAlign={'left'}
                color={'gray.900'}
                mb={5}
                fontSize={{ base: '2xl', md: '3xl', lg: '5xl' }} // Responsive font size
              >
                {params.id}
              </Heading>
              <Text textAlign={'left'} fontSize={{ base: 'md', md: 'xl' }} color={'gray.900'}>
                {serviceObj.mainPara}
              </Text>
            </Stack>
            <Heading
              textAlign={'left'}
              color={'gray.900'}
              mb={5}
              fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }} // Responsive font size
            >
              {serviceObj.mainLine}
            </Heading>
            <AboutImg image={serviceObj.serviceImg2}/>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
              {serviceObj.mainpoints.map((point,index) => {
                return (
                  <Box key={index}>
                    <br />
                    <Text
                      textAlign={'left'}
                      fontFamily={'heading'}
                      fontSize={{ base: '2xl', md: '3xl' }} // Responsive font size
                      color={'gray.900'}
                      mb={3}
                      fontWeight={'600'}
                    >
                      {point.pointTitle}
                    </Text>
                    <Text
                      textAlign={'left'}
                      fontSize={{ base: 'md', md: 'xl' }} // Responsive font size
                      color={'gray.900'}
                    >
                      {point.pointLine}
                    </Text>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Box>
    </Box>
            <Box p={4}>
      <Stack
        spacing={4}
        as={Container}
        maxW={'3xl'}
        textAlign={'center'}
        marginTop={'60px'}
      >
        <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
          {serviceObj.serviceInfoMain}
        </Heading>
        <Divider borderColor={'#000'} mt={3} />
      </Stack>

      <Box mt={10} textAlign={'left'} align={'center'} maxW="1200px" mx="auto">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {serviceObj.serviceInfo.map((info,index) => {
            return (
              <HStack key={index} align={'top'} style={{borderWidth:"1px"}} p={5}>
                <Box color={'green.400'} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                {/* <hr borderWidth="1px" /> */}
                <VStack align={'start'}>
                  <Text fontWeight={600} fontSize={{ base: 'lg', md: 'xl' }}>
                    {info.topic}
                  </Text>
                  <ul>
                    {info.topicPoints.map((pt, index) => {
                      return (
                        <li key={index}>
                          <Text textAlign={'left'} fontSize={{ base: 'md', md: 'xl' }}>
                            {pt}
                          </Text>
                        </li>
                      );
                    })}
                  </ul>
                </VStack>
              </HStack>
            );
          })}
        </SimpleGrid>
      </Box>

          </Box>
        </Box>
      ) : (
        <Box>No Data Found</Box>
      )}
      </Box>
    </Box>
  );
};

export default Service;
