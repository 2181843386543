import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
// import { NAV_ITEMS } from '../../Assets/objects';
import { Link } from 'react-router-dom';

import {
  HamburgerIcon,
  CloseIcon,
} from '@chakra-ui/icons';
// import { ColorModeSwitcher } from '../Theme/ColorModeSwitcher';

import Logo from '../../Assets/images/logo.png';


const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box 
    bgGradient="linear(to-r, #010914, #010914, #010914 )"
    color={useColorModeValue('gray.100', 'white')}>
      <Flex
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        maxW="1200px"
        mx="auto" 
        >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
          color={'gray.900'}
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} color={'gray.900'} /> : <HamburgerIcon w={5} h={5} color={'gray.900'} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Box>
              <Link to="/">
                <Image w="auto" h={'30px'} src={Logo} alt="Logo" />
              </Link>
            </Box>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button
            as={Link}
            to="/contact"
            rounded={'full'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            bg={' #ED1651'}
            _hover={{
              bg: '#fff',
              color: '#000'
            }}>
            Get Started
          </Button>
          {/* <ColorModeSwitcher /> */}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );

};

const MobileNav = () => {

  return (
    <Stack bg={useColorModeValue('white', 'gray.900')} p={4} display={{ md: 'none' }} bgGradient="linear(to-r, #010914, #010914, #010914 )"
    color={useColorModeValue('gray.100', 'white')}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href, to }) => {
  return (
    <Stack spacing={4}>
      <Box
        py={2}
        style={{justifyContent:'space-between', alignItems:'center'}}
        _hover={{
          textDecoration: 'none',
        }}>

      <Link
        py={2}
        to={to}
        style={{justifyContent:'space-between', alignItems:'center'}}
        fontWeight={600}
        color={useColorModeValue('gray.900', 'gray.900')}
      >
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
      </Link>
      </Box>
    </Stack>
  )
}


const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
              <Link
                to={navItem.to}
                fontSize={'lg'}
                fontWeight={500}
                p={2}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                  }}
              >
                {navItem.label}
              </Link>

        </Box>
      ))}
    </Stack>
  )
}

const NAV_ITEMS = [
  {
    label: 'Home',
    href: '#',
    to: '/',
  },
  {
    label: 'About Us',
    href: '#',
    to: '/about',
  },
  {
    label: 'Service',
    href: '#',
    to: '/services',
  },
  {
    label: 'Contact Us',
    href: '#',
    to: '/contact',
  },
  // {
  //   label: 'Blog',
  //   href: '#',
  //   to: '/blog',
  // },
]

export default Navbar;
