import React from 'react'
import {
  VStack, Heading, Text, Button
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <VStack
      h="80vh"
      marginBottom={'10px'}
      justify="center"
      spacing={6}
      textAlign="center"
      bgGradient="linear(to-br,rgb(3, 2, 8), #30298E)"
      bgClip={'text'}
      // color="#000"
      p={6}
    >
      <Heading fontSize="9xl" fontWeight={'extrabold'}>404</Heading>
      <Heading size="2xl">Oops! Page Not Found</Heading>
      <Text fontSize="lg">
        The page you're looking for doesn't exist or has been moved.
      </Text>
      <Button
                  as={Link}
                  to="/"
                  rounded={'full'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={' #ED1651'}
                  _hover={{
                    bg: '#fff',
                    color: '#000'
                  }}>
                  Go Home
                </Button>
    </VStack>
  )
}

export default PageNotFound