import { Box, Heading, Flex, Divider, Image } from "@chakra-ui/react";
// import CtImg from '../../Assets/images/client-logo.png'
import P1 from "../../Assets/images/Patner Logo/dell-technologies-logo.svg"
import P2 from "../../Assets/images/Patner Logo/microsoft-6.svg"
import P3 from "../../Assets/images/Patner Logo/qnap-logo-2004.svg"
import P4 from "../../Assets/images/Patner Logo/sophos-logo.svg"
import P5 from "../../Assets/images/Patner Logo/vmware-5.svg"
import P6 from "../../Assets/images/Patner Logo/lenovo-logo-2015.svg"
import P7 from "../../Assets/images/Patner Logo/windows-server.svg"

const partners = [
  { src: P1, name: "Dell" },
  { src: P2, name: "Microsoft" },
  { src: P3, name: "QNAP" },
  { src: P4, name: "SOPHOS" },
  { src: P5, name: "VMware" },
  { src: P6, name: "Lenovo" },
  { src: P7, name: "Windows Server" },
];

const PartnersSection = () => {
  return (
    <Box textAlign="center" py={10} mb={10}>
      {/* Title with lines */}
      <Flex align="center" justify="center" gap={4}>
        <Divider w="20%" borderColor="gray.400" />
        <Heading as="h2" size="2xl" fontWeight="bold">
          Our Partners
        </Heading>
        <Divider w="20%" borderColor="gray.400" />
      </Flex>

      {/* Logos Section */}
      <Flex justify="center" align="center" gap={6} m={3} mt={6} wrap="wrap">
        {partners.map((partner, index) => (
          <Flex key={index} align="center" direction="column">
            <Image src={partner.src} alt={partner.name} height={'25px'}/>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default PartnersSection;
