import React from 'react';
import {
  VStack,
  Heading,
  Text,
  Button,
  Divider,
  Container,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.lg" py={12} px={6}>
      <VStack
        spacing={6}
        textAlign="left"
        bg="gray.50"
        p={[4, 6, 8]}
        borderRadius="lg"
        align="start"
      >
        <Heading size="2xl" color="#ED1651" textAlign="center" w="full">
          Privacy Policy
        </Heading>

        <Text fontSize="lg" color="gray.900">
          This Privacy Policy ("Policy") applies to codise-it.com, and CODISE
          ("Company") and governs data collection and usage. For the purposes of
          this Privacy Policy, unless otherwise noted, all references to the
          Company include codise-it.com. The Company's website is a IT-Service
          and Technical Support site. By using the Company website, you consent
          to the data practices described in this statement.
        </Text>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} w={'100%'} textAlign="left">
            Collection of your Personal Information
          </Heading>
          <Text fontSize="md" color="gray.700">
            In order to better provide you with products and services offered,
            the Company may collect personally identifiable information, such as
            your :
          </Text>
          <Text fontSize="md" color="gray.800">
            - First Name and Last Name <br />
            - Email Address <br />- Phone Number
          </Text>
          <Text fontSize="md" color="gray.700">
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an account; (b) entering a sweepstakes or contest sponsored by us or
            one of our partners; (c) signing up for special offers from selected
            third parties; (d) sending us an email message; (e) submitting your
            credit card or other payment information when ordering and
            purchasing products and services. To wit, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </Text>
        </VStack>

        <Divider />
        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Use of your Personal Information
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company collects and uses your personal information in the following ways :
          </Text>
          <Text fontSize="md" color="gray.800">
            - to operate and deliver the services you have requested
            <br />
            - to provide you with information, products, or services that you request from us 
            <br />
            - to provide you with notices about your account
            <br/>
            - to carry out the Company's obligations and enforce our rights arising from any contracts entered between you and us, including for billing and collection
            <br />
            - to notify you about changes to our codise-it.com or any products or services we offer or provide through it
            <br/>
            - in any other way we may describe when you provide the information
            <br/>
            - for any other purpose with your consent
          </Text>
          <Text fontSize="md" color="gray.700">
          The Company may also use your personally identifiable information to inform you of other
products or services available from the Company and its affiliates.
          </Text>
        </VStack>

        <Divider />

        
        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Sharing Information with Third Parties
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company does not sell, rent, or lease its customer lists to third parties.</Text>
          <Text fontSize="md" color="gray.700">
          The Company may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (email, name, address, phone number) is not transferred to the third party. The Company may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services tothe Company, and they are required to maintain the confidentiality of your information. 
          </Text>
          <Text fontSize="md" color="gray.700">
          The Company may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on the Company or the site; (b) protect and defend the rights or property of the Company; and/or (c) act under exigent circumstances to protect the personal safety of users of the Company, or the public. 
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Tracking User Behavior
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company may keep track of the websites and pages our users visit within the Company, in order to determine what the Company services are the most popular. This data is used to deliver customized content and advertising within the Company to customers whose behavior indicates that they are interested in a particular subject area.  
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Automatically Collected Information 
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company may automatically collect information about your computer hardware and software. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding the use of the Company's website. 
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Use of Cookies
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company's website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. 
          </Text>
          <Text fontSize="md" color="gray.700">
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the web server that you have returned to a specific page. For example, if you personalize the Company's pages, or register with Company's site or services, a cookie helps the Company to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same website, the information you previously provided can be retrieved, so you can easily use the Company's features that you customized.
          </Text>
          <Text fontSize="md" color="gray.700">
          You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Company's services or websites you visit. 
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Links 
          </Heading>
          <Text fontSize="md" color="gray.700">
          This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. 
          </Text>
        </VStack>

        <Divider />
        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Security of your Personal Information 
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company secures your personal information from unauthorized access, use, or disclosure. The Company uses the following methods for this purpose :
          </Text>
          <Text fontSize="md" color="gray.800">
            - SSL Protocol
            <br />
        </Text>
          <Text fontSize="md" color="gray.700">
          We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet that are beyond our control; and (b) the security, integrity, and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed. 
          </Text>
        </VStack>

        <Divider />
        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Right to Deletion
          </Heading>
          <Text fontSize="md" color="gray.700">
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will :  
          </Text>
          <Text fontSize="md" color="gray.800">
            - Delete your personal information from our records; and 
            <br />
            - Direct any service providers to delete your personal information from their records.
        </Text>
          <Text fontSize="md" color="gray.700">
          Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
          </Text>
          <Text fontSize="md" color="gray.800">
          - Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, and provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; 
            <br />
            - Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; 
            <br />
            - Debug to identify and repair errors that impair existing intended functionality; 
            <br/>
            - Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; 
            <br/>
            - Comply with the California Electronic Communications Privacy Act; 
            <br/>
            - Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; 
            <br/>
            - Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;
            <br/> 
            - Comply with an existing legal obligation; or 
            <br/>
            - Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. 
        </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Children Under Thirteen 
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company does not knowingly collect personally identifiable information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission to use this website. 
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Email Communications 
          </Heading>
          <Text fontSize="md" color="gray.700">
          From time to time, the Company may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. 
          </Text>
        </VStack>

        <Divider />

        
        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Changes to This Statement 
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company reserves the right to change this Policy from time to time. For example, when there are changes in our services, changes in our data protection practices, or changes in the law. When changes to this Policy are significant, we will inform you. You may receive a notice by sending an email to the primary email address specified in your account, by placing a prominent notice on our CODISE, and/or by updating any privacy information. Your continued use of the website and/or services available after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and be bound by that Policy. 
          </Text>
        </VStack>

        <Divider />

        <VStack gap={4}>
          <Heading size="lg" color="gray.800" mb={2} textAlign={'left'} w={'100%'}>
          Contact Information 
          </Heading>
          <Text fontSize="md" color="gray.700">
          The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:
          </Text>
          <Text>
          Email Address: info@codise.us 
          <br/>
          Phone Number: +1 (323) 301-7101 
          <br/>
          Effective as of January 01, 2025
          </Text>
        </VStack>

        <Divider />

        {/* Call to Action */}
        <Box w="full" textAlign="center">
          <Button as={Link} to="/" colorScheme="teal" size="lg">
            Go Back to Home
          </Button>
        </Box>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicy;
