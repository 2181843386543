import React from "react";
import {
  Stack,
  Text,
  Button,
  Heading,
  // Image,
  Box,
  Grid,
  GridItem,
  VStack,
  Flex,
  Divider
} from "@chakra-ui/react";
// import WImage from "../../../Assets/images/whatapp.png";
import ContactForm from "../../../Components/ContactForm";
import FooterTop from "../../FooterTop"

const ContactDets = () => {
  return (
    <Box>
    <Box py={16} px={{ base: 4, md: 6 }} align={'center'}
    maxW="1200px"
    mx="auto">
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
        gap={10}
        alignItems="center"
      >
        {/* Left Section */}
        <GridItem >
          <VStack align="stretch" spacing={6}>
            <Box borderRadius="lg" p={6} bg="gray.50" boxShadow="md">
              <Stack spacing={4}>
                <Heading as="h4" fontSize={{ base: "2xl", md: "3xl" }}>
                  Get in touch with CODISE-IT
                </Heading>
                <Divider />
                <Text fontSize={{ base: "md", md: "lg" }}>
                  At CODISE-IT, we value your queries and are ready to assist
                  you with personalized IT solutions. Whether you need expert
                  advice, service details, or support, our team is just a
                  message away.
                </Text>

                {/* Contact Buttons */}
                <Flex
                  direction={{ base: "column", sm: "row" }}
                  gap={4}
                  justify="center"
                >
                  <Button
                  href="mailto:info@codise-it.com"
                    as="a"
                    height="60px"
                    width="50%"
                    variant="outline"
                    colorScheme="pink"
                    rounded={'full'}
                    color={'#ED1651'}
                    // leftIcon={<Image src={WImage} height="30px" />}
                  >
                    info@codise-it.com
                  </Button>
                  {/* <Button
                    href="https://wa.me/15629779228?text=I'm%20inquiring%20about%20the%20It%20Service"
                    as="a"
                    height="60px"
                    width="100%"
                    colorScheme="whatsapp"
                    rounded={'full'}
                    leftIcon={<Image src={WImage} height="30px" />}
                  >
                    Message Us on WhatsApp
                  </Button> */}
                </Flex>

                {/* Working Hours */}
                <Box>
                  <Text fontSize="xl" fontWeight="bold">
                    Working Hours
                  </Text>
                  <Text fontSize="md">
                    Monday - Friday: 9am - 5pm
                    <br />
                    Saturday - Sunday: Remote Support Only
                  </Text>
                </Box>
              </Stack>
            </Box>
          </VStack>
        </GridItem>

        {/* Right Section: Contact Form */}
        <GridItem boxShadow="md" borderRadius="lg" bg="white" h={'100%'}>
            <ContactForm />
        </GridItem>
      </Grid>
    </Box>
    <FooterTop />
    </Box>
  );
};

export default ContactDets;
