import React from "react";
import {
  Box,
  Flex,
  VStack,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";

const AboutDets = () => {
  const aboutData = [
    {
      id: 1,
      title: "Proven expertise",
      desc: "CODISE-IT delivers reliable and effective IT solutions through its extensive experience and team of expert professionals. Our organizational history clearly shows our consistent capability to address challenging situations while achieving remarkable client outcomes.",
    },
    {
      id: 2,
      title: "Customized solutions",
      desc: "We understand that every business is unique. Our professionals develop specialized solutions which meet your specific needs and provide both seamless integration and optimal outcomes.",
    },
    {
      id: 3,
      title: "Proactive support",
      desc: "Continuous system operation stability is maintained through our 24/7 monitoring and support services. We identify and address potential problems early which helps to reduce system downtime and disruptions.",
    },
    {
      id: 4,
      title: "Robust security measures",
      desc: "Our highest priority is safeguarding your business from cyber threats. We protect your data and systems through the use of firewalls and advanced threat detection measures.",
    },
    {
      id: 5,
      title: "Scalability and growth",
      desc: "Our solutions grow with your business to support your continued expansion. Our service model provides flexibility that adapts to the evolving needs of both startups and large enterprises.",
    },
    {
      id: 6,
      title: "Cost optimization",
      desc: "Through cost-effective technology solutions we deliver high-quality value as you maximize your IT investments.",
    },
  ];

  return (
    <Container maxW="6xl" mt={10} px={{ base: 4, md: 6 }}>
      {aboutData.map((aboutItem) => (
        <Box key={aboutItem.id} mb={6}>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={6}
            alignItems="center"
          >
            {/* Title Section */}
            <GridItem>
              <VStack align={{ base: "center", md: "flex-start" }} spacing={4}>
                <chakra.h1
                  fontSize={{ base: "lg", md: "xl" }}
                  fontWeight="700"
                  textAlign={{ base: "center", md: "start" }}
                >
                  {aboutItem.title}
                </chakra.h1>
              </VStack>
            </GridItem>

            {/* Description Section */}
            <GridItem>
              <Flex>
                <chakra.p
                  fontSize={{ base: "md", md: "lg" }}
                  textAlign={{ base: "center", md: "justify" }}
                  fontWeight="400"
                  lineHeight="1.6"
                >
                  {aboutItem.desc}
                </chakra.p>
              </Flex>
            </GridItem>
          </Grid>

          <Divider my={5} />
        </Box>
      ))}
    </Container>
  );
};

export default AboutDets;
