import React from 'react';
import {
  Box,
  Stack,
  Text,
  Container,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';

const SectionTop = ({title,desc}) => {
  return (
    <Box
    borderRadius={'0 0 50px 50px'}
    bgGradient="linear(to-r, #010914, #010914, #010914 )"
    color={useColorModeValue('gray.100', 'white')} py={10}
    >
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading
            color={'#ED1651'}
            fontSize={{ base: '4xl', sm: '6xl' }}
            fontWeight={'extrabold'}
          >
            {title}
          </Heading>
          <Text color={'gray.200'} fontSize={{ base: 'sm', sm: 'lg' }}>
            {desc}
          </Text>
        </Stack>
      </Box>
  )
}

export default SectionTop